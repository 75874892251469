


















import { defineComponent, PropType } from '@vue/composition-api'
import { Dropdown } from '@/components/molecules/Dropdown'
import { DropdownMulti } from '@/components/molecules/DropdownMulti'
import { CountryCode } from '@/enums'

export default defineComponent({
  name: 'CountryCodeSelect',
  components: { Dropdown, DropdownMulti },
  props: {
    value: {
      type: [String, Array] as PropType<string | string[] | undefined>,
      default: undefined,
    },
    isDisabled: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    codes: {
      type: Array as PropType<string[]>,
      default: () => Object.keys(CountryCode),
    },
  },
  computed: {
    countryCode: {
      get(): string | string[] | null {
        return this.value || null
      },
      set(code: string | string[]) {
        this.$emit('input', code)
      },
    },
    dropdownItems() {
      return this.codes.map((code: string) => ({
        text: this.$t(`common.countryCode.${code}`),
        value: code,
      }))
    },
  },
})

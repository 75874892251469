import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { IMaterialFormat } from '@/interfaces'

interface IKey {
  id: string
}

export interface IMeta {
  lastEvaluatedKey?: IKey
}

interface IFetchQuery {
  materialFormatGroupId: string
  startKey?: string
  limit?: number
}

export interface IFormItems {
  id: string | null
  name: string
  materialFormatGroupId: string | null
  organisationId: string | null
  description: string
  internalDescription: string
}

const defaultFormItems: IFormItems = {
  id: null,
  name: '',
  materialFormatGroupId: null,
  organisationId: null,
  description: '',
  internalDescription: '',
}

interface ISaveQuery {
  id?: string
  materialFormatGroupId: string
  name: string
  organisationId: string
  description: string
  internalDescription: string
}

export const fetchMaterialFormats = async (
  organisationId: string,
  query?: IFetchQuery
) => {
  const response = await HttpService.request({
    query,
    path: `/organisations/${organisationId}/material-formats`,
  })

  return response as {
    materialFormats: IMaterialFormat[]
    meta?: IMeta
  }
}

export const saveMaterialFormatFromFormItems = async (
  formItems: IFormItems
): Promise<IMaterialFormat> => {
  const saveQuery = {
    name: formItems.name,
    description: formItems.description,
    materialFormatGroupId: formItems.materialFormatGroupId,
    organisationId: formItems.organisationId,
  } as ISaveQuery

  if (formItems.id) {
    saveQuery.id = formItems.id
  }

  return saveMaterialFormat(saveQuery)
}

export const saveMaterialFormat = async (
  query: ISaveQuery
): Promise<IMaterialFormat> => {
  const response = await HttpService.request({
    path: `/material-formats/${query.id || ''}`,
    method: query.id ? HttpMethod.Put : HttpMethod.Post,
    body: query,
  })

  return response as IMaterialFormat
}

export const getDefaultFormItems = (organisationId: string): IFormItems => {
  return Object.assign({}, defaultFormItems, {
    organisationId,
  })
}

export const deleteMaterialFormat = async (
  materialFormat: IMaterialFormat
): Promise<IMaterialFormat> => {
  const response = await HttpService.request({
    path: `/material-formats/${materialFormat.id}`,
    method: HttpMethod.Delete,
  })

  return response as IMaterialFormat
}

export const getFormItemsFromMaterialFormats = (
  materialFormat: IMaterialFormat
): IFormItems => {
  return {
    id: materialFormat.id,
    name: materialFormat.name,
    materialFormatGroupId: materialFormat.materialFormatGroupId,
    organisationId: materialFormat.organisationId,
    description: materialFormat.description,
    internalDescription: materialFormat.internalDescription || '',
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"bigger-text",attrs:{"items":_vm.value,"headers":_vm.tableHeaders,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":""},on:{"change":_vm.change},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.day",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.getWeekDay(index))+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(!item.isActive)?_c('span',{staticClass:"closed"},[_vm._v(" "+_vm._s(_vm.$t('workingHours.closed'))+" ")]):_c('time-picker',{on:{"change":_vm.change},model:{value:(item.from),callback:function ($$v) {_vm.$set(item, "from", $$v)},expression:"item.from"}})]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('time-picker',{attrs:{"min-time":item.from},on:{"change":_vm.change},model:{value:(item.to),callback:function ($$v) {_vm.$set(item, "to", $$v)},expression:"item.to"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
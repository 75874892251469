





























































import { saveMaterialFormatFromFormItems } from '@/utils/MaterialFormatUtil/MaterialFormatUtil'
import { Dropdown } from '@/components/molecules/Dropdown'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'FormatForm',
  components: { Dropdown, TextField },
  props: {
    formItemsData: {
      type: Object,
      default: null,
    },
    materialFormatGroups: {
      type: Array,
      default: () => [],
    },
    setMaterialFormat: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formItems: this.formItemsData,
      isSetFormatLoading: false,
      rules: {
        name: {
          required: true,
          message: this.$t('organisation.formats.form.name.required'),
          trigger: 'blur',
        },
        materialFormatGroupId: {
          required: true,
          message: this.$t(
            'organisation.formats.form.materialFormatGroupId.required'
          ),
          trigger: 'blur',
        },
      },
    }
  },
  methods: {
    validateFormat() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }

        this.isSetFormatLoading = true

        try {
          const format = await saveMaterialFormatFromFormItems(this.formItems)

          this.isSetFormatLoading = false
          this.setMaterialFormat(format)
          this.$emit('close-dialog')
        } catch (error: any) {
          this.isSetFormatLoading = false
          this.$message.error(error.message)
        }
      })
    },
  },
}












































































































































































import {
  IWorkingDayFormItems,
  saveStationFromFormItems,
  saveStationFiles,
  fetchStations,
} from '@/utils/StationUtils/StationUtil'
import CountryCodeSelect from '@/components/common/CountryCodeSelect/CountryCodeSelect.vue'
import FileUploadComponent from '../common/FileUploadComponent.vue'
import { IFileListItem } from '@/interfaces'
import WorkingHoursSelectComponent from '@/components/common/WorkingHoursSelectComponent.vue'
import moment from 'moment'
import WorkingHoursListComponent from '@/components/common/WorkingHoursListComponent.vue'
import { mapGetters } from 'vuex'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'StationForm',
  components: {
    WorkingHoursListComponent,
    CountryCodeSelect,
    FileUploadComponent,
    WorkingHoursSelectComponent,
    TextField,
  },
  inject: ['enums'],
  props: {
    setStation: {
      type: Function,
      required: true,
    },
    formItemsData: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
    },
  },
  data() {
    return {
      formItems: this.formItemsData,
      uploadedFilesCount: 0,
      isSetStationLoading: false,
      fileList: [],
      station: {},
      rules: {
        name: {
          required: true,
          message: this.$t('organisation.stations.form.name.required'),
          trigger: 'blur',
        },
        addressStreetName: {
          required: true,
          message: this.$t('common.form.addressStreetName.required'),
          trigger: 'blur',
        },
        addressStreetNumber: {
          required: true,
          message: this.$t('common.form.addressStreetNumber.required'),
          trigger: 'blur',
        },
        addressPostCode: {
          required: true,
          message: this.$t('common.form.addressPostCode.required'),
          trigger: 'blur',
        },
        addressCity: {
          required: true,
          message: this.$t('common.form.addressCity.required'),
          trigger: 'blur',
        },
        addressCountyCode: {
          required: true,
          message: this.$t('common.form.addressCountyCode.required'),
          trigger: 'blur',
        },
        workingDays: {
          required: true,
          type: 'array',
          trigger: 'blur',
          validator: (
            rule: object,
            value: Array<IWorkingDayFormItems>,
            callback: any
          ) => {
            if (!value || !value.filter((item) => item !== null).length) {
              return callback(
                new Error(this.$t('offerForm.form.workingHours.required'))
              )
            }

            const activeDays = value.filter((data) => data.isActive)

            if (activeDays) {
              activeDays.forEach((day) => {
                if (!day.from || !day.to) {
                  return callback(
                    new Error(this.$t('offerForm.form.workingHours.validator'))
                  )
                }

                if (
                  moment(day.from, 'hh:mm').isAfter(moment(day.to, 'hh:mm'))
                ) {
                  return callback(
                    new Error(
                      this.$t('offerForm.validation.workingHours.wrongRange')
                    )
                  )
                }
              })
            }

            return callback()
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
  },
  methods: {
    onWorkingDaysChange() {
      this.$refs.form.validateField('workingDays')
    },
    async validateStation() {
      await this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }

        this.isSetStationLoading = true

        try {
          this.station = await saveStationFromFormItems(this.formItems)

          await this.$nextTick()
          this.fileList = this.$refs.fileUpload.getFileList()
          await this.$refs.fileUpload.submitUpload(this.station.id)

          await this.handleCloseDialog()
        } catch (error) {
          this.isSetStationLoading = false
          this.$message.error(error.message)
        }
      })
    },
    async removeFile(fileList: Array<IFileListItem>) {
      try {
        const station = await saveStationFiles(this.formItems.id, fileList)
        this.setStation(station)
      } catch (error) {
        this.$message.error(error.message)

        return Promise.reject(error)
      }
    },
    async handleCloseDialog() {
      if (
        !this.station ||
        this.fileList.length - (this.station.documents || []).length !==
          this.uploadedFilesCount
      ) {
        return
      }

      const response = await fetchStations(this.station.organisationId)
      await this.setStation(
        response.stations.find((station: any) => station.id === this.station.id)
      )

      this.isSetStationLoading = false

      setTimeout(this.$emit('close-dialog'), this.fileList.length ? 500 : 0)
    },
    onFileUploadSuccess() {
      this.uploadedFilesCount++
      this.handleCloseDialog()
    },
  },
}




































import { PropType } from 'vue'
import moment from 'moment'
import { IWorkingDayFormItems } from '@/utils/StationUtils/StationUtil'
import { TimePicker } from '@/components/molecules/TimePicker'

export default {
  name: 'WorkingHoursSelectComponent',
  components: {
    TimePicker,
  },
  props: {
    value: {
      type: Array as PropType<Array<IWorkingDayFormItems>>,
      default: () => [],
    },
  },
  computed: {
    tableHeaders() {
      return [
        { text: '', value: 'checkbox', width: 40 },
        { text: this.$t('workingHours.labels.day'), value: 'day', width: 180 },
        { text: this.$t('workingHours.labels.startHours'), value: 'start' },
        { text: this.$t('workingHours.labels.endHours'), value: 'end' },
      ]
    },
  },
  methods: {
    getWeekDay(index: number) {
      return moment().weekday(index).format('dddd')
    },
    change() {
      this.$emit('change')
    },
  },
}

import HttpService from '@/services/HttpService/HttpService'
import { IFetchQuery, IMeta } from '@/utils/TableDataUtil/TableDataUtil'
import { IPaymentCondition } from '@/interfaces'

export interface IFormItems {
  id: string | null
  name: string
  organisationId: string | null
  paymentTargetInDays: number | null
  discountAmount: number | null
  discountValidity: number | null
}

const defaultFormItems: IFormItems = {
  id: null,
  name: '',
  organisationId: null,
  paymentTargetInDays: null,
  discountAmount: null,
  discountValidity: null,
}

export interface ISaveQuery {
  id?: string
  name: string
  organisationId: string
  paymentTargetInDays: number
  discountAmount: number | null
  discountValidity: number | null
}

export const fetchPaymentConditions = async (
  organisationId: string,
  query?: IFetchQuery
) => {
  const response = await HttpService.request({
    query,
    path: `/organisations/${organisationId}/payment-conditions`,
  })

  return response as {
    paymentConditions: IPaymentCondition[]
    meta?: IMeta
  }
}

export const getDefaultFormItems = (organisationId: string): IFormItems =>
  Object.assign({}, defaultFormItems, {
    organisationId,
  })

export const getFormItemsFromPaymentCondition = (
  paymentCondition: IPaymentCondition
): IFormItems => ({
  id: paymentCondition.id,
  name: paymentCondition.name,
  organisationId: paymentCondition.organisationId,
  paymentTargetInDays: paymentCondition.paymentTargetInDays,
  discountAmount: paymentCondition.discountAmount || null,
  discountValidity: paymentCondition.discountValidity || null,
})













import { computed, defineComponent, PropType } from '@vue/composition-api'

interface ISelectItem {
  text?: string
  value?: string
  divider?: boolean
  disabled?: boolean
}

const getTimes = () => {
  const times: string[] = []
  for (let hour = 0; hour < 24; hour++) {
    ;['00', '15', '30', '45'].forEach((minutes) => {
      const composedTime = `${hour}:${minutes}`
      times.push(composedTime)
    })
  }
  return times
}

const getItems = (times: string[], minTime: string | false) => {
  const items: ISelectItem[] = []
  let minTimeWasReached = false
  let shouldBeDisabled = false
  times.forEach((time) => {
    if (minTime !== false) {
      if (!minTimeWasReached && minTime === time) {
        minTimeWasReached = true
      }
      shouldBeDisabled = !minTimeWasReached
    }
    if (!time.startsWith('0') && time.endsWith('00')) {
      items.push({ divider: true })
    }
    items.push({
      text: time,
      value: time,
      disabled: shouldBeDisabled,
    })
  })
  return items
}

export default defineComponent({
  props: {
    // to be used on the internal v-model
    value: {
      type: String,
      default: '',
    },
    minTime: {
      type: [String, Boolean] as PropType<string | false>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const times = computed(() => getTimes())
    const items = computed(() => getItems(times.value, props.minTime))

    const time = computed({
      get: () => props.value,
      set: (newValue: string) => {
        if (times.value.includes(newValue)) {
          emit('input', newValue)
          emit('change', newValue)
        } else {
          emit('input', '')
        }
      },
    })

    return { time, items }
  },
})






















import { PropType } from 'vue'
import { IFileListItem } from '@/interfaces'
import {
  getFileUploadOptions,
  deleteFile,
  uploadFile,
  downloadFile,
} from '@/utils/DocumentUtil/DocumentUtil'
import { FileAction, S3DocumentType } from '@/enums'

export default {
  name: 'FileUploadComponent',
  inject: ['enums'],
  props: {
    id: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    fileList: {
      type: Array as PropType<Array<IFileListItem>>,
      default: [],
    },
    type: {
      type: String as PropType<S3DocumentType | undefined>,
      default: undefined,
    },
    removeFile: {
      type: Function as PropType<Promise<void> | undefined>,
      default: undefined,
    },
    isAutoUpload: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },
  },
  data() {
    return {
      fileUploadOptions: null,
      typeId: null,
    }
  },
  computed: {
    currentFileList(): Array<IFileListItem> {
      if (this.fileList) {
        return this.fileList.map((item: string) => {
          return { name: item, url: item }
        })
      }

      return []
    },
  },
  watch: {
    id: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.typeId = this.id
      },
    },
  },
  methods: {
    onSuccess() {
      this.$emit('onFileUploadSuccess')
    },
    async onBeforeRemove(file: File, fileList: Array<IFileListItem>) {
      let fileDeleteOptions

      try {
        fileDeleteOptions = await getFileUploadOptions(
          this.typeId || this.id,
          this.type,
          file,
          FileAction.Delete
        )
      } catch (error) {
        this.$message.error(error.message)
        throw error
      }

      await deleteFile(fileDeleteOptions)

      if (this.removeFile) {
        await this.removeFile(
          fileList
            .map((item: IFileListItem) => item.name)
            .filter((name) => name !== file.name)
        )
      }
    },
    async onPreview(file: IFileListItem) {
      try {
        const options = await downloadFile(this.id, this.type, file.name)

        document.location.assign(options.url)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    async updateFileUploadOptions(file: File) {
      try {
        this.fileUploadOptions = await getFileUploadOptions(
          this.typeId || this.id,
          this.type,
          file,
          FileAction.Upload
        )
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    async uploadFile() {
      if (this.fileUploadOptions) {
        await uploadFile(this.fileUploadOptions)

        if (this.onSuccess) {
          this.onSuccess()
        }
      }
    },
    getFileList() {
      return this.$refs.fileUploadComponent.uploadFiles
    },
    submitUpload(id?: string) {
      this.$refs.fileUploadComponent.submit(id)
    },
  },
}

import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import {
  IFileListItem,
  IStation,
  IWorkingDay,
  ICommonFetchQuery,
  IMeta,
} from '@/interfaces'

export interface ISaveFileQuery {
  id: string
  documents: IFileListItem[]
}

export interface IWorkingDayFormItems {
  from: string
  to: string
  isActive: boolean
}

export interface IFormItems {
  id: string | null
  name: string
  description: string
  organisationId: string
  contactEmail: string
  contactPhoneNumber: string
  contactMobilePhoneNumber: string
  contactFaxNumber: string
  addressStreetName: string
  addressStreetNumber: string
  addressAdditional: string
  addressPostCode: string
  addressCity: string
  addressCountyCode: string
  documents: string[] | null
  workingDays: IWorkingDayFormItems[]
}

export interface ISaveQuery {
  id?: string
  name: string
  description: string | null
  organisationId: string
  contactEmail: string | null
  contactPhoneNumber: string | null
  contactMobilePhoneNumber: string | null
  contactFaxNumber: string | null
  addressStreetName: string
  addressStreetNumber: string
  addressAdditional: string | null
  addressPostCode: string
  addressCity: string
  addressCountyCode: string
  workingDays: Array<IWorkingDay | null>
}

export interface IFetchQuery extends ICommonFetchQuery {
  search?: string
}

export const getDefaultWorkingDayFormItems = (): IWorkingDayFormItems => {
  return {
    from: '',
    to: '',
    isActive: false,
  }
}

const getDefaultWorkingDaysFormItems = () => {
  return Array(7)
    .fill(null)
    .map(() => getDefaultWorkingDayFormItems())
}

export const defaultFormItems: IFormItems = {
  id: null,
  name: '',
  description: '',
  organisationId: '',
  contactEmail: '',
  contactPhoneNumber: '',
  contactMobilePhoneNumber: '',
  contactFaxNumber: '',
  addressStreetName: '',
  addressStreetNumber: '',
  addressAdditional: '',
  addressPostCode: '',
  addressCity: '',
  addressCountyCode: '',
  documents: null,
  workingDays: getDefaultWorkingDaysFormItems(),
}

const getWorkingDaysFromFormItems = (
  workingDaysFormItems: IWorkingDayFormItems[]
): Array<IWorkingDay | null> => {
  return workingDaysFormItems.map((formItems) => {
    if (formItems.isActive) {
      return {
        from: formItems.from,
        to: formItems.to,
      }
    } else {
      return null
    }
  })
}

const getFormItemsFromWorkingDays = (
  workingDays: Array<IWorkingDay | null>
): IWorkingDayFormItems[] => {
  return workingDays.map((workingDay) => {
    if (workingDay === null) {
      return getDefaultWorkingDayFormItems()
    } else {
      return {
        from: workingDay.from,
        to: workingDay.to,
        isActive: true,
      }
    }
  })
}

export const saveStation = async (
  query: ISaveQuery | ISaveFileQuery
): Promise<IStation> => {
  const response = await HttpService.request({
    path: `/stations/${query.id || ''}`,
    method: query.id ? HttpMethod.Put : HttpMethod.Post,
    body: query,
  })

  return response as IStation
}

export const saveStationFiles = async (
  id: string,
  documents: IFileListItem[]
): Promise<IStation> => {
  const saveQuery: ISaveFileQuery = { id, documents }

  return saveStation(saveQuery)
}

export const fetchStations = async (
  organisationId: string,
  query?: IFetchQuery
) => {
  const response = await HttpService.request({
    query,
    path: `/organisations/${organisationId}/stations`,
  })

  return response as {
    stations: IStation[]
    meta?: IMeta
  }
}

export const saveStationFromFormItems = async (
  formItems: IFormItems
): Promise<IStation> => {
  if (
    !formItems.name ||
    !formItems.organisationId ||
    !formItems.addressStreetName ||
    !formItems.addressStreetNumber ||
    !formItems.addressStreetNumber ||
    !formItems.addressPostCode ||
    !formItems.addressCity ||
    !formItems.addressCountyCode
  ) {
    return Promise.reject(new Error('Form items where not validated correctly'))
  }

  const saveQuery: ISaveQuery = {
    name: formItems.name,
    description: formItems.description || null,
    organisationId: formItems.organisationId,
    contactEmail: formItems.contactEmail || null,
    contactPhoneNumber: formItems.contactPhoneNumber || null,
    contactMobilePhoneNumber: formItems.contactMobilePhoneNumber || null,
    contactFaxNumber: formItems.contactFaxNumber || null,
    addressStreetName: formItems.addressStreetName,
    addressStreetNumber: formItems.addressStreetNumber,
    addressAdditional: formItems.addressAdditional || null,
    addressPostCode: formItems.addressPostCode,
    addressCity: formItems.addressCity,
    addressCountyCode: formItems.addressCountyCode,
    workingDays: getWorkingDaysFromFormItems(formItems.workingDays),
  }

  if (formItems.id) {
    saveQuery.id = formItems.id
  }

  return saveStation(saveQuery)
}

export const deleteStation = async (station: IStation): Promise<IStation> => {
  const response = await HttpService.request({
    path: `/stations/${station.id}`,
    method: HttpMethod.Delete,
  })

  return response as IStation
}

export const getFormItemsFromStation = (station: IStation): IFormItems => {
  return {
    id: station.id,
    name: station.name,
    description: station.description,
    organisationId: station.organisationId || defaultFormItems.organisationId,
    contactEmail: station.contactEmail || defaultFormItems.contactEmail,
    contactPhoneNumber:
      station.contactPhoneNumber || defaultFormItems.contactPhoneNumber,
    contactMobilePhoneNumber:
      station.contactMobilePhoneNumber ||
      defaultFormItems.contactMobilePhoneNumber,
    contactFaxNumber:
      station.contactFaxNumber || defaultFormItems.contactFaxNumber,
    addressStreetName:
      station.addressStreetName || defaultFormItems.addressStreetName,
    addressStreetNumber:
      station.addressStreetNumber || defaultFormItems.addressStreetNumber,
    addressAdditional:
      station.addressAdditional || defaultFormItems.addressAdditional,
    addressPostCode:
      station.addressPostCode || defaultFormItems.addressPostCode,
    addressCity: station.addressCity || defaultFormItems.addressCity,
    addressCountyCode:
      station.addressCountyCode || defaultFormItems.addressCountyCode,
    documents: station.documents || defaultFormItems.documents,
    workingDays: station.workingDays
      ? getFormItemsFromWorkingDays(station.workingDays)
      : defaultFormItems.workingDays,
  }
}

export const getDefaultFormItems = (organisationId: string): IFormItems => {
  return Object.assign({}, defaultFormItems, {
    organisationId,
  })
}

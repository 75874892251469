












import { formatNumber } from '@/utils/utils'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'NumberInput',
  components: { TextField },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    default: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    fractionDigits: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDefaultEmptyString: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActive: false,
      dirtyValue: '',
    }
  },
  computed: {
    inputValue: {
      get() {
        if (this.isActive) {
          return this.dirtyValue
        } else if (this.isDefaultEmptyString && this.value === this.default) {
          return ''
        } else {
          return formatNumber(this.value, this.fractionDigits)
        }
      },
      set(dirtyValue: string) {
        this.dirtyValue = dirtyValue
      },
    },
  },
  methods: {
    onBlur() {
      let value = this.dirtyValue
        ? this.getValueFromDirtyValue(this.dirtyValue)
        : this.default || 0

      if (typeof this.min === 'number' && value < this.min) {
        value = this.min
      } else if (typeof this.max === 'number' && value > this.max) {
        value = this.max
      }

      this.isActive = false
      this.dirtyValue = formatNumber(value, this.fractionDigits)
      this.$emit('input', value)
      this.$emit('change')
    },
    onFocus() {
      this.isActive = true
      this.dirtyValue =
        this.value === (this.default || 0)
          ? ''
          : this.getDirtyValueFromValue(this.value)
    },
    getDirtyValueFromValue(value: number): string {
      return value.toString().replace('.', ',')
    },
    getValueFromDirtyValue(dirtyValue: string): number {
      const valueString = dirtyValue.replace(/[^-0-9.,]/g, '').replace(',', '.')
      const multiplicator = Math.pow(10, this.fractionDigits)

      return (
        Math.round((parseFloat(valueString) || 0) * multiplicator) /
        multiplicator
      )
    },
  },
}































































import { PropType } from 'vue'
import { mapActions } from 'vuex'
import { TextField } from '../molecules/TextField'
import { IFormItems } from '@/utils/PaymentConditionUtil/PaymentConditionUtil'
import { Checkbox } from '@/components/molecules/Checkbox'

export default {
  name: 'PaymentConditionForm',
  components: { Checkbox, TextField },
  props: {
    formItemsData: {
      type: Object as PropType<IFormItems>,
      default: null,
    },
  },
  data() {
    return {
      isSavePaymentConditionLoading: false,
      formItems: this.formItemsData,
      isDiscounted: false,
    }
  },
  created() {
    this.rules = {
      name: {
        required: true,
        message: this.$t('organisation.paymentConditions.form.name.required'),
        trigger: 'blur',
      },
      paymentTargetInDays: [
        {
          required: true,
          message: this.$t(
            'organisation.paymentConditions.form.paymentTargetInDays.required'
          ),
          trigger: 'blur',
        },
        {
          pattern: /^[0-9]*$/,
          message: this.$t(
            'organisation.paymentConditions.form.paymentTargetInDays.pattern'
          ),
          trigger: 'blur',
        },
      ],
      discountAmount: [
        {
          required: true,
          message: this.$t(
            'organisation.paymentConditions.form.discountAmount.required'
          ),
          trigger: 'blur',
        },
        {
          pattern: /^[0-9]*$/,
          message: this.$t(
            'organisation.paymentConditions.form.discountAmount.pattern'
          ),
          trigger: 'blur',
        },
      ],
      discountValidity: [
        {
          required: true,
          message: this.$t(
            'organisation.paymentConditions.form.discountValidity.required'
          ),
          trigger: 'blur',
        },
        {
          pattern: /^[0-9]*$/,
          message: this.$t(
            'organisation.paymentConditions.form.discountValidity.pattern'
          ),
          trigger: 'blur',
        },
      ],
    }

    if (this.formItems.discountAmount) {
      this.isDiscounted = true
    }
  },
  methods: {
    ...mapActions('organisation', ['savePaymentCondition']),
    validatePaymentCondition() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }

        this.isSavePaymentConditionLoading = true

        try {
          if (!this.isDiscounted) {
            this.formItems.discountAmount = null
            this.formItems.discountValidity = null
          }

          const condition = await this.savePaymentCondition(this.formItems)

          this.isSavePaymentConditionLoading = false

          this.$emit('close-dialog')
          this.$emit('created', condition)
        } catch (error) {
          this.isSavePaymentConditionLoading = false
          this.$message.error(error.message)
        }
      })
    },
  },
}

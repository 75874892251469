









import { PropType } from 'vue'
import { IWorkingDayFormItems } from '@/utils/StationUtils/StationUtil'
import moment from 'moment'
import { groupBy } from '@/utils/utils'

interface IWorkingHoursItem {
  id: number
  from: string
  to: string
  label: string
}

export default {
  name: 'WorkingHoursListComponent',
  props: {
    workingDays: {
      type: Array as PropType<Array<IWorkingDayFormItems>>,
      default: () => [],
    },
  },
  computed: {
    workingHourItems() {
      const items: Array<IWorkingHoursItem> = []
      let id = 0

      this.workingDays.forEach((item: IWorkingDayFormItems, index: number) => {
        if (!item || (!item.from && !item.to)) {
          return
        }

        const prevIndex = index - 1

        if (this.workingDays[prevIndex]) {
          if (
            this.workingDays[prevIndex].from !== item.from ||
            this.workingDays[prevIndex].to !== item.to
          ) {
            id++
          }
        }

        items.push({
          id,
          label: moment().weekday(index).format('ddd'),
          from: item.from,
          to: item.to,
        })
      })

      return groupBy(items, 'id')
    },
  },
  methods: {
    getWeekDayRange(item: Array<IWorkingHoursItem>) {
      if (item.length > 1) {
        return `${item[0].label} - ${item[item.length - 1].label}`
      } else {
        return item[0].label
      }
    },
  },
}
